@use "@jeffcarbine/premmio/public/styles/mixin";

@use "../patreon/patreonCollage";
@use "../news/newsPost";

#latestNews {
  h1 {
    position: relative;
    z-index: 2;
  }

  .newsPost {
    position: relative;
    z-index: 1;
  }
}

@media (width > mixin.$breakpoint-md) {
  #latestProducts {
    width: 200%;
  }
}
